// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-projet-js": () => import("../src/templates/projet.js" /* webpackChunkName: "component---src-templates-projet-js" */),
  "component---src-templates-mobilier-js": () => import("../src/templates/mobilier.js" /* webpackChunkName: "component---src-templates-mobilier-js" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-a-propos-js": () => import("../src/pages/a-propos.js" /* webpackChunkName: "component---src-pages-a-propos-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-presse-js": () => import("../src/pages/presse.js" /* webpackChunkName: "component---src-pages-presse-js" */)
}

